<template>
  <div>
    <b-button
      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
      variant="success"
      size="sm"
      class="mr-1 mb-1"
      @click="onAddRequestClicked()"
    >
      <feather-icon
        icon="PlusIcon"
        class="mr-30"
      />
      <span>Yeni İş İlanı</span>
    </b-button>
    <div class="mb-1">
      <b-row
        class="text-center font-weight-bold"
      >
        <b-col
          cols="12"
        >
          <b-button
            v-ripple.400="'rgba(186, 191, 199, 0.15)'"
            variant="outline-danger"
            block
            disabled
          >
            İŞ İLANLARIM
          </b-button>
        </b-col>
      </b-row>
    </div>
    <div>
      <b-row>
        <b-col
          cols="6"
          md="2"
          class="border font-weight-bold text-danger"
        >
          Toplam Talep:
        </b-col>
        <b-col
          cols="6"
          md="2"
          class="border"
        >
          {{ summary.talepsay }}
        </b-col>
        <b-col
          cols="6"
          md="2"
          class="border font-weight-bold text-danger"
        >
          Toplam Eşleşen Aday:
        </b-col>
        <b-col
          cols="6"
          md="2"
          class="border"
        >
          {{ summary.eslesen }}
        </b-col>
        <b-col
          cols="6"
          md="2"
          class="border font-weight-bold text-danger"
        >
          Yönlendirme Olanlar
        </b-col>
        <b-col
          cols="6"
          md="2"
          class="border"
        >
          {{ summary.yontalep }}
        </b-col>
      </b-row>
      <b-row>
        <b-col
          cols="6"
          md="2"
          class="border font-weight-bold text-danger"
        >
          Aktif Talep:
        </b-col>
        <b-col
          cols="6"
          md="2"
          class="border"
        >
          {{ summary.aktifler }}
        </b-col>
        <b-col
          cols="6"
          md="2"
          class="border font-weight-bold text-danger"
        >
          Toplam Elenen Aday:
        </b-col>
        <b-col
          cols="6"
          md="2"
          class="border"
        >
          {{ summary.elenen }}
        </b-col>
        <b-col
          cols="6"
          md="2"
          class="border font-weight-bold text-danger"
        >
          Kapalı Talepler
        </b-col>
        <b-col
          cols="6"
          md="2"
          class="border"
        >
          {{ summary.kapali }}
        </b-col>
      </b-row>
      <b-row>
        <b-col
          cols="6"
          md="2"
          class="border font-weight-bold text-danger"
        >
          Kapatılmış Talep:
        </b-col>
        <b-col
          cols="6"
          md="2"
          class="border"
        >
          {{ summary.kapali }}
        </b-col>
        <b-col
          cols="6"
          md="2"
          class="border font-weight-bold text-danger"
        >
          Toplam Yönlendirilen Aday:
        </b-col>
        <b-col
          cols="6"
          md="2"
          class="border"
        >
          {{ summary.yonlendirilen }}
        </b-col>
        <b-col
          cols="6"
          md="2"
          class="border font-weight-bold text-danger"
        >
          Pasif Talepler
        </b-col>
        <b-col
          cols="6"
          md="2"
          class="border"
        >
          {{ summary.pasifler }}
        </b-col>
      </b-row>
    </div>
    <br>
    <div class="mb-1">
      <b-row
        class="text-center"
      >
        <b-col
          cols="12"
        >
          <b-button
            v-ripple.400="'rgba(186, 191, 199, 0.15)'"
            variant="outline-danger"
            block
            disabled
          >
            TALEP LİSTE EKRANI
          </b-button>
        </b-col>
      </b-row>
    </div>
    <br>
    <!-- TalepListesi -->
    <div
      v-for="(r , i) in trows"
      :key="i"
      class="mb-3 border pl-2 pt-1 pb-1"
    >
      <b-row
        style="width:100%"
      >
        <b-col
          cols="6"
          md="2"
          class="border font-weight-bold text-danger"
        >
          Talep Durumu
        </b-col>
        <b-col
          cols="6"
          md="2"
          class="border"
        >
          <b-badge :variant="durumvar(r.requestStatus)">
            {{ durum(r.requestStatus) }}
          </b-badge>
          &nbsp;
          <b-badge :variant="yon(r.yonlendirilen).drmvar">
            {{ yon(r.yonlendirilen).drmadi }}
          </b-badge>
        </b-col>
        <b-col
          cols="6"
          md="2"
          class="border font-weight-bold text-danger"
        >
          Talep Tarihi:
        </b-col>
        <b-col
          cols="6"
          md="2"
          class="border"
        >
          {{ r.requestOpenDate.substring(0,10) }}
        </b-col>
        <b-col
          cols="6"
          md="1"
          class="border font-weight-bold text-danger"
        >
          Alınacak Aday
        </b-col>
        <b-col
          cols="6"
          md="1"
          class="border"
        >
          {{ r.personelCount }}
        </b-col>
        <b-col
          cols="12"
          md="2"
        >
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="info"
            size="sm"
            class="mb-1"
            block
            :disabled="r.requestStatus === 2"
            @click="onUpdateReqBtnClicked(r)"
          >
            Talep Detay
          </b-button>
        </b-col>
      </b-row>
      <b-row
        style="width:100%"
      >
        <b-col
          cols="6"
          md="2"
          class="border font-weight-bold text-danger"
        >
          Pozisyon Faktör
        </b-col>
        <b-col
          cols="6"
          md="2"
          class="border"
        >
          {{ r.staffPositionName }}
        </b-col>
        <b-col
          cols="6"
          md="2"
          class="border font-weight-bold text-danger"
        >
          Personel Sınıfı:
        </b-col>
        <b-col
          cols="6"
          md="2"
          class="border"
        >
          {{ r.staffClass }}
        </b-col>
        <b-col
          cols="6"
          md="1"
          class="border font-weight-bold text-danger"
        >
          Eşlenen Aday:
        </b-col>
        <b-col
          cols="6"
          md="1"
          class="border"
        >
          {{ r.eslesen }}
        </b-col>
        <b-col
          cols="12"
          md="2"
          class="mb-1"
        >
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="warning"
            size="sm"
            block
            :disabled="r.requestStatus === 2"
            @click="onUygunYonlendirme(r)"
          >
            Yönlendirmeler
          </b-button>
        </b-col>
      </b-row>
      <b-row
        style="width:100%"
      >
        <b-col
          cols="6"
          md="2"
          class="border font-weight-bold text-danger"
        >
          Aylık Ücret:
        </b-col>
        <b-col
          cols="6"
          md="2"
          class="border"
        >
          {{ r.salary }}
        </b-col>
        <b-col
          cols="6"
          md="2"
          class="border font-weight-bold text-danger"
        >
          Performans Ödemesi:
        </b-col>
        <b-col
          cols="6"
          md="2"
          class="border"
        >
          {{ r.hasPerformanceFee }}
        </b-col>
        <b-col
          cols="6"
          md="1"
          class="border font-weight-bold text-danger"
        >
          Elenen Aday:
        </b-col>
        <b-col
          cols="6"
          md="1"
          class="border"
        >
          {{ r.elenen }}
        </b-col>
        <b-col
          cols="12"
          md="2"
        >
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="success"
            size="sm"
            class="mb-1"
            block
            :disabled="r.requestStatus === 2"
            @click="onEslesmeOzet(r)"
          >
            Eşleşme Özeti
          </b-button>
        </b-col>
      </b-row>
      <b-row
        style="width:100%"
      >
        <b-col
          cols="6"
          md="2"
          class="border font-weight-bold text-danger"
        >
          Olumlu Aday:
        </b-col>
        <b-col
          cols="6"
          md="2"
          class="border"
        >
          {{ r.olumlu }}
        </b-col>
        <b-col
          cols="6"
          md="2"
          class="border font-weight-bold text-danger"
        >
          Yönlendirilen Aday:
        </b-col>
        <b-col
          cols="6"
          md="2"
          class="border"
        >
          {{ r.yonlendirilen }}
        </b-col>
        <b-col
          cols="6"
          md="1"
          class="border font-weight-bold text-danger"
        >
          Olumsuz Aday:
        </b-col>
        <b-col
          cols="6"
          md="1"
          class="border"
        >
          {{ r.olumsuz }}
        </b-col>
        <b-col
          cols="12"
          md="2"
        >
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            :variant="butvar(r.requestStatus)"
            size="sm"
            class="mb-1"
            block
            :disabled="r.requestStatus === 2"
            @click="onPasifBtnClicked(r)"
          >
            {{ r.requestStatus === 0 || r.requestStatus === 1 ? "Pasif Yap" : "Aktif Yap" }}
          </b-button>
        </b-col>
      </b-row>
      <b-row
        style="width:100%"
      >
        <b-col
          class="border font-weight-bold text-danger"
          cols="4"
          md="4"
        >
          Kısa Görev Tanımı:
        </b-col>
        <b-col
          class="border"
          cols="8"
          md="6"
        >
          {{ r.shortPositionDescription }}
        </b-col>
        <b-col
          cols="12"
          md="2"
        >
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="danger"
            size="sm"
            class="mb-1"
            block
            :disabled="r.requestStatus === 2"
            @click="onKapatBtnClicked(r)"
          >
            Talebi Kapat
          </b-button>
        </b-col>
      </b-row>
      <b-row
        style="width:100%"
      >
        <b-col
          class="border font-weight-bold text-danger pb-1"
          cols="4"
          md="4"
        >
          İşverene Mesaj:
        </b-col>
        <b-col
          class="border"
          cols="8"
          md="6"
        >
          Mesaj
        </b-col>
      </b-row>
    </div>
  </div>
</template>
<script>
import {
  BButton, VBModal, BRow, BCol, BBadge,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import * as apiTalep from '@/api/islemler/firmRequest'
import * as apiFirm from '@/api/islemler/firminventory'
import * as apiEval from '@/api/islemler/Evaluation'
import * as apiApl from '@/api/islemler/applicant/applicantEducation'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    BButton,
    BRow,
    BCol,
    BBadge,

  },
  directives: {
    Ripple,
    'b-modal': VBModal,
  },
  props: {
    firmId: {
      type: Number,
      required: false,
      default: -1,
    },
    userObject: {
      type: Object,
      required: false,
      default: null,
    },
    firmData: {
      type: Object,
      required: false,
      default: null,
    },
  },
  data() {
    return {
      trows: [],
      rows: [],
      reqs: [],
      faktor: '',
      firmam: {},
      talep: {},
      summary: {
        talepsay: 0, aktifler: 0, pasifler: 0, kapali: 0, eslesen: 0, yonlendirilen: 0, elenen: 0, olumsuz: 0, olumlu: 0, belirsiz: 0, yontalep: 0,
      },
      fr: {},
      say: 0,
      drm: { adi: 'Pasif', var: 'secondary' },
    }
  },
  computed: {

  },
  watch: {
    firmId() {
      this.getSummary()
      this.getrequests()
    },
  },

  methods: {
    fieldfn(rowObj) {
      return `<img width="80" heigt="80" src="${rowObj.firmInfo.firmLogo}" />`
    },
    getrequests() {
      apiTalep.GetStaffRequestByFirmId(this.firmId).then(ret => {
        this.reqs = ret.data.list
        this.summary.talepsay = this.reqs.length
        this.summary.aktifler = this.reqs.filter(req => req.requestStatus === 1 || req.requestStatus === 0).length
        this.summary.pasifler = this.reqs.filter(req => req.requestStatus === -1).length
        this.summary.kapali = this.reqs.filter(req => req.requestStatus === 2).length
        this.summary.yontalep = 0
        this.reqs.forEach((item, i) => {
          const rw = {}
          rw.yonlendirilen = 0
          rw.elenen = 0
          apiEval.EvaluationStaffRequestSummary(item.staffRequestId).then(req => {
            this.fr = req.data.entity
            this.say = i
            this.summary.eslesen += this.fr.evaluationOpenPositionViewModels.length
            rw.eslesen = this.fr.evaluationOpenPositionViewModels.length
            rw.belirsiz = this.fr.evaluationOpenPositionViewModels.filter(rel => rel.resultState === 1 || rel.resultState === 7 || rel.resultState === '').length
            rw.olumsuz = this.fr.evaluationOpenPositionViewModels.filter(rel => rel.resultState === 2 || rel.resultState === 3 || rel.resultState === 4).length
            rw.olumlu = this.fr.evaluationOpenPositionViewModels.filter(rel => rel.resultState === 5 || rel.resultState === 6).length
            this.fr.evaluationOpenPositionViewModels.forEach((apl, y) => {
              if (apl.redirectionState === 1 && apl.applicant.status === 1) {
                this.say = y
                this.summary.yonlendirilen += 1
                rw.yonlendirilen += 1
              }
              if (apl.evaluationState === 0) {
                this.summary.elenen += 1
                rw.elenen += 1
              }
            })
            if (rw.yonlendirilen >= 1) {
              this.summary.yontalep += 1
            }
          })

          rw.staffRequestId = item.staffRequestId
          rw.requestStatus = item.requestStatus
          rw.requestOpenDate = item.requestOpenDate
          rw.personelCount = item.personelCount
          rw.staffPositionName = item.factorPosition.staffPositionName
          rw.staffClass = item.staffClass
          rw.salary = `${item.salaryMin.toString()} - ${item.salaryMax.toString()}`
          rw.hasPerformanceFee = item.hasPerformanceFee === true ? 'Evet' : 'Hayır'
          rw.shortPositionDescription = item.shortPositionDescription
          this.trows.push(rw)
        })
      })
    },
    getSummary() {
      apiTalep.GetSummaryByFirmId(0, this.firmId).then(res => {
        this.rows = res.data.list
      })
      apiFirm.GetFirm(this.firmId).then(ret => {
        this.firmam = ret.data.entity
      })
    },
    getImg(row) {
      return `${row.firmInfo.firmLogo}`
    },
    clearModal() {
    },
    onDataGridItemClicked(params) {
      return params
    },
    onPasifBtnClicked(row) {
      let talep = null
      apiTalep.GetStaffRequestById(row.staffRequestId).then(res => {
        talep = res.data.entity
        const durum = talep.requestStatus
        if (durum === -1) {
          talep.requestStatus = 0
        }
        if (durum === 0 || durum === 1) {
          talep.requestStatus = -1
        }
        apiTalep.UpdateStaffRequest(talep).then(() => {
          this.trows.splice(0)
          this.getrequests()
        })
      })
    },
    onKapatBtnClicked(row) {
      const h = this.$createElement
      const messageVNode = h('div', [
        h('p', [`Firmanızın ${row.staffPositionName} talebi kapatılacaktır ve bir daha işlem yapamayacaksınız!!`]),
        h('p', { class: ['text-center font-weight-bold text-danger'] }, ['Emin misiniz?']),
      ])
      this.$bvModal
        .msgBoxConfirm(messageVNode, {
          title: 'Talep Kapatma Onayı',
          size: 'sm',
          okVariant: 'primary',
          okTitle: 'Evet',
          cancelTitle: 'Hayır',
          cancelVariant: 'outline-secondary',
          hideHeaderClose: false,
          centered: true,
        })
        .then(value => {
          if (value) {
            let talep = null
            apiTalep.GetStaffRequestById(row.staffRequestId).then(res => {
              talep = res.data.entity
              const durum = talep.requestStatus
              if (durum === -1) {
                talep.requestStatus = 2
              }
              if (durum === 0 || durum === 1) {
                talep.requestStatus = 2
              }
              apiTalep.UpdateStaffRequest(talep).then(() => {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: 'Talep Durum',
                    text: `Firmanızın ${row.staffPositionName} talebi kapatılmıştır..`,
                    icon: 'PlayIcon',
                    variant: 'success',
                  },
                })
                this.trows.splice(0)
                this.getrequests()
              })
            })
          }
        })
    },
    onDuzenleBtnClicked(row) {
      this.$router.push({ name: 'yeni-talep', params: { firmStaffRequestId: row.staffRequestId, firmInfo: row.firmInfo } })
    },
    onAddRequestClicked() {
      this.$router.push({ name: 'yeni-talep', params: { firmInfo: this.firmam, firmStaffRequestId: -1 } })
    },
    onUpdateReqBtnClicked(row) {
      this.$router.push({ name: 'yeni-talep', params: { firmStaffRequestId: row.staffRequestId, firmInfo: this.firmam } })
    },
    onEslesmeOzet(row) {
      this.$router.push({ name: 'talep-eslesme-ozet', params: { firmStaffRequestId: row.staffRequestId } })
    },
    onUygunYonlendirme(row) {
      let tlp = null
      apiEval.EvaluationStaffRequestSummary(row.staffRequestId).then(res => {
        tlp = res.data.entity
        tlp.evaluationOpenPositionViewModels.forEach(element => {
          apiEval.GetEvaluationOpenPositionsByApplicantId(element.applicant.applicantId).then(ret => {
            apiApl.GetApplicantEducationsByApplicantId(element.applicant.applicantId).then(edu => {
              Object.assign(element.applicant, { educationLevel: edu.data.entity.educationLevel.educationLevelDescription })
              let bolum = ''
              switch (edu.data.entity.educationLevel.educationLevelId) {
                case 6: bolum = edu.data.entity.vocationHighSchool
                  break
                case 7: bolum = edu.data.entity.vocationCollege
                  break
                case 8: bolum = edu.data.entity.university
                  break
                case 9: bolum = edu.data.entity.postgraduate
                  break
                default: bolum = ''
                  break
              }
              Object.assign(element.applicant, { education: bolum })
              apiEval.GetApplicantEvaluationCvPointsSummaryByApplicantId(element.applicant.applicantId).then(cv => {
                Object.assign(element.applicant, { totalPersonalScore: cv.data.entity.totalPersonalScore })
              })
              let essay = 0
              let yonsay = 0
              essay = ret.data === null ? 0 : ret.data.count
              yonsay = ret.data === null ? 0 : ret.data.list.filter(x => x.redirectionState === 1 || x.redirectionState === 2).length
              Object.assign(element.applicant, { evoluationCount: essay })
              Object.assign(element.applicant, { redirectionCount: yonsay })
            })
          })
        })
      })
      this.$router.push({ name: 'talep-uygun-yonlendirme', params: { firmStaffRequestId: row.staffRequestId, r: tlp } })
    },
    durum(st) {
      let drmadi = ''
      switch (st) {
        case 1: case 0:
          drmadi = 'Aktif'
          break
        case -1:
          drmadi = 'Pasif'
          break
        case 2:
          drmadi = 'Kapalı'
          break
        default:
          drmadi = 'Pasif'
      }
      return drmadi
    },
    durumvar(st) {
      let drmvar = ''
      switch (st) {
        case 1: case 0:
          drmvar = 'light-success'
          break
        case -1:
          drmvar = 'light-danger'
          break
        case 2:
          drmvar = 'light-dark'
          break
        default:
          drmvar = 'light-secondary'
      }
      return drmvar
    },
    butvar(st) {
      switch (st) {
        case 1: case 0:
          return 'secondary'
        case -1:
          return 'primary'
        default:
          return 'primary'
      }
    },
    yon(st) {
      const drm = { drmvar: '', drmadi: '' }
      if (st >= 1) {
        drm.drmvar = 'light-success'
        drm.drmadi = 'Yönlendirilmiş'
      } else {
        drm.drmvar = 'light-danger'
        drm.drmadi = 'Yönlendirilmemiş'
      }
      return drm
    },
  },
}
</script>
<style lang='scss'>
   @import '@core/scss/vue/libs/vue-good-table.scss';
</style>
