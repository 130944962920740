<template>
  <div>
    <taleplerim
      :firm-id="FirmInfoId"
      :user-object="userObj"
      :firm-data="FirmInfo"
    />
  </div>
</template>
<script>
import Ripple from 'vue-ripple-directive'
import taleplerim from '@/views/islemler/talep/taleplerim.vue'

import * as auth from '@/utils/auth'
import * as apiFirm from '@/api/islemler/firminventory'

export default {
  components: {
    taleplerim,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      userObj: {},
      FirmInfoId: 0,
      FirmInfo: {},
    }
  },
  beforeCreate() {
    this.userObj = auth.getUserObject()
    apiFirm.GetFirmByUserId(this.userObj.UserId).then(res => {
      this.FirmInfo = res.data.entity
      this.FirmInfoId = res.data.entity.firmInfoId
    })
  },
}
</script>
<style lang='scss'>
   @import '@core/scss/vue/libs/vue-select.scss';
</style>
